import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Box } from 'theme-ui'
import SEO from '../components/seo'
import styles from "./privacy-policy.module.css"
import downloadFile from '../../static/pdf/I-COMM SOLUTIONS SAS - Política de Tratamiento de Datos Personales v1.pdf'

const PrivacyPolicyPage = () => (
  <React.Fragment>
    <SEO title='Política de Tratamiento de Datos Personales' />
    <Box
      sx={{
        textAlign: 'left',
        p: [15, 30],
        maxWidth: 1200,
        margin: '0 auto',
      }}
    >
      <Box as="h1" sx={{ fontSize: [35, 50], mb: [15, 30], textAlign: 'center' }}>
        Política de Tratamiento de Datos Personales
      </Box>
      <Box
        sx={{
          display: 'block',
          bg: 'bg_header',
          border: '1px solid',
          borderColor: 'border',
          textAlign: 'justify',
          p: [20, 30],
        }}>
        <p className={styles.textCenter}>
          <a href={downloadFile}>DESCARGAR PDF</a>
        </p>

        <p>La compañía <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, comprometida con el respeto de los Derechos de los titulares en el Tratamiento de Datos Personales, adopta la presente política con el fin de dar cumplimiento a la regulación vigente y definir el marco de tratamiento de los datos de carácter personal que recoja, almacene, use y circule de conformidad con lo dispuesto en la Ley Estatutaria 1581 de 2012 y el Decreto 1074 de 2015, Único Reglamentario del Sector Comercio, Industria y
          Turismo y demás normas concordantes.</p>

        <h3>I. IDENTIFICACIÓN</h3>
        <p><span className={styles.blackColor}>RESPONSABLE DEL TRATAMIENTO DE DATOS PERSONALES</span>: Actuará como Responsable del Tratamiento de Datos Personales, <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, Sociedad por Acciones Simplificadas, Legalmente constituida con numero de <span className={styles.blackColor}>NIT. 900428495-5</span>, actualmente activa.</p>
        <p>Para todos los efectos del presente documento, se tendrá por dirección y canales de atención los siguientes:</p>
        <ul>
          <li>DIRECCIÓN FISICA: Carrera 47A # 101A - 58, Bogotá.</li>
          <li>CORREO ELECTRÓNICO: <a href='mailto:protecciondedatos@i-comm.co'>protecciondedatos@i-comm.co</a></li>
          <li>TELEFÓNO DEL RESPONSABLE: +57 601 6377015</li>
        </ul>

        <h3>II. MARCO LEGAL</h3>
        <p>El Tratamiento de Datos Personales debe realizarse respetando las normas generales y especiales sobre la materia y para actividades permitidas de acuerdo al artículo 15 de la Constitución Política de Colombia, que establece que todas las personas tienen derecho a conocer, actualizar y rectificar las informaciones que se hayan recogido sobre ellas en bancos de datos, por persona natural o jurídica y en archivos de entidades públicas y privadas. Marco normativo y de referencia legal de Colombia:</p>
        <ul>
          <li>Artículo 15 de la Constitución Política de Colombia.</li>
          <li>Ley Estatutaria 1266 de 2008.</li>
          <li>Ley 1273 de 2009.</li>
          <li>Ley Estatutaria 1581 de 2012.</li>
          <li>Decreto 1074 de 2015 -Decreto Único Reglamentario del Sector Comercio, Industria y Turismo, en el cual se encuentran compilados los Decretos Reglamentarios 1727 de 2009, 2952 de 2010, 1377 de 2013 y 886 de 2014</li>
        </ul>

        <h3>III. ÁMBITO DE APLICACIÓN</h3>
        <p>Esta Política de Tratamiento de Protección de Datos Personales, aplica a todos los colaboradores en todos los niveles e instancias, con los que <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> establezca alguna relación, comercial, laboral, directa o indirecta y todas las demás relacionadas con el desarrollo de los fines de tratamiento en desarrollo de las funciones propias y en su calidad de responsable y/o como encargado del tratamiento de los mismos, como: i) Empleados, Clientes, Proveedores, Visitantes y aquellas otras personas con las cuales exista una relación con <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, ii) Representantes Legales, miembros directivos, y/o socios, iii) y demás que custodien y traten bases de datos de carácter personal. iv) Contratistas y personas naturales o jurídicas que presten sus servicios en <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> bajo cualquier tipo de modalidad contractual, en virtud de la cual se realice cualquier tratamiento de datos de carácter personal, v) Personas públicas y privadas en condición de usuarios de los datos personales y vi) las demás personas que establezca la ley.</p>
        <p>Así mismo, esta Política es de estricto cumplimiento para todos los encargados del tratamiento de las bases de datos e implica estricta confidencialidad en relación con los datos personales tratados. La presente Política será aplicable a los datos de carácter personal que traten, circulen, almacenen o se encueten registrados en archivos físico y/o electrónicos o cualquiera de las base de datos de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> cuyo titular sea una persona natural.</p>

        <h3>IV. DEFINICIONES</h3>
        <p>Para una mejor compresión de la presente política de Tratamiento de Datos Personales conforme a la legislación vigente, nos permitimos relacionar términos para los diferentes grupos de interés.</p>
        <p><span className={styles.blackColor}>AUTORIZACIÓN</span>: Consentimiento previo, expreso e informado del titular para llevar a cabo el Tratamiento de Datos Personales.</p>
        <p><span className={styles.blackColor}>AVISO DE PRIVACIDAD</span>: Comunicación verbal o escrita generada por el responsable del Tratamiento de Datos Personales y dirigida a quien se le va a realizar dicho tratamiento, en la cual se le informa acerca de la existencia de las políticas que le serán aplicables y la forma de acceder a las mismas, así como las finalidades que se pretende dar a los datos personales.</p>
        <p><span className={styles.blackColor}>BASE DE DATOS</span>: Conjunto organizado de datos personales bien sea en medio físico o en medio electrónico que sea objeto de tratamiento.</p>
        <p><span className={styles.blackColor}>CONFIDENCIALIDAD</span>: Característica que posee cualquier información no divulgada de una persona natural o jurídica que no entra dentro del ámbito de la naturaleza pública.
        </p>
        <p><span className={styles.blackColor}>CONTRATISTA</span>: Persona natural o jurídica que se contrata para la prestación de servicios de acuerdo a las necesidades del contratante, por un valor determinado, asumiendo todos los riesgos, para prestar tales servicios con sus propios medios y con libertad y autonomía técnica y directiva.</p>
        <p><span className={styles.blackColor}>DATO PERSONAL</span>: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.</p>
        <p><span className={styles.blackColor}>DATO PÚBLICO</span>: Es el dato calificado como tal según los mandatos de la ley o de la Constitución Política y todos aquellos que no sean semiprivados o privados de conformidad con la ley. Son públicos, entre otros, los datos contenidos en documentos públicos, sentencias judiciales debidamente ejecutoriadas que no estén sometidos a reserva y los relativos al estado civil de las personas.</p>
        <p><span className={styles.blackColor}>DATO SEMIPRIVADO</span>: Es el dato que no tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no sólo a su titular sino a cierto sector o grupo de personas o a la sociedad en general, como el dato financiero y crediticio de actividad comercial.</p>
        <p><span className={styles.blackColor}>DATO PRIVADO</span>: Es el dato que por su naturaleza íntima o reservada sólo es relevante para el titular.</p>
        <p><span className={styles.blackColor}>DATOS SENSIBLES</span>: Aquellos datos que afectan la intimidad del titular o cuyo uso indebido puede generar su discriminación, como aquellos que revelen el origen racial o étnico, la orientación política, las convicciones religiosas, filosóficas o discapacidad, la pertenencia a sindicatos, organizaciones sociales, de Derechos humanos o que promueva intereses de cualquier partido político o que garanticen los Derechos y garantías de partidos políticos de oposición, así como los datos relativos a la salud, a la vida sexual y los datos biométricos; la información genética, los que revelen ideologías y los hábitos de las personas, el domicilio, las cartas, las conversaciones telefónicas, las relaciones personales de índole estrecha o aquello cuyo conocimiento esté reservado exclusivamente a los miembros de una determinada relación jurídica, así como los defectos o imperfecciones físicas, el ámbito de las creencias y la conciencia y las peculiaridades de la individualidad de la persona.</p>
        <p><span className={styles.blackColor}>ENCARGADO DEL TRATAMIENTO</span>: Persona natural o jurídica, pública o privada que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta del responsable del tratamiento.</p>
        <p><span className={styles.blackColor}>HABEAS DATA</span>: Derecho constitucional que tienen todas las personas a conocer, incluir, actualizar, rectificar o corregir y excluir las informaciones que se hayan recogido sobre ellas en bases de datos o archivos, y los demás derechos, libertades y garantías a que se refiere el artículo 15 de la Constitución Política.</p>
        <p><span className={styles.blackColor}>OFICIAL DE TRATAMIENTO DE DATOS</span>: Persona encargada dentro de la Organización de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, que tiene como fin velar por la implementación efectiva de las políticas y procedimientos adoptados para cumplir las normas de Tratamiento de Datos Personales, así como la implementación de buenas prácticas de gestión de datos personales.</p>
        <p><span className={styles.blackColor}>RESPONSABLE DEL TRATAMIENTO</span>: <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> es responsable del tratamiento de los datos cuando por sí misma o en asocio con otras compañías, personas naturales, instituciones o entidades decida sobre las bases de datos y/o el tratamiento de los datos personales de los Titulares.</p>
        <p><span className={styles.blackColor}>RIESGO</span>: Representa la posibilidad de ocurrencia de un evento, cuyas consecuencias negativas puedan entorpecer el normal desarrollo de las funciones de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, afectando el logro de sus objetivos que garantizan el adecuado tratamiento de la información de datos Personales.</p>
        <p><span className={styles.blackColor}>TITULAR</span>: Persona natural cuyos datos personales son objeto de Tratamiento.</p>
        <p><span className={styles.blackColor}>TRATAMIENTO</span>: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.</p>
        <p><span className={styles.blackColor}>TRANSFERENCIA DE DATOS</span>: Tiene lugar cuando el responsable y/o encargado del Tratamiento de Datos Personales, ubicado en Colombia, envía la información o los datos personales a un receptor, que a su vez es responsable del tratamiento y se encuentra dentro o fuera del país.</p>
        <p><span className={styles.blackColor}>TRANSMISIÓN</span>: Tratamiento de Datos Personales que implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la realización de un tratamiento por el encargado por cuenta del responsable.</p>
        <p><span className={styles.blackColor}>TERCERO</span>: Persona natural o jurídica que interactúa con <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> para el ejercicio de actividades o funciones propias en cumplimiento de su objeto social o de una relación contractual, así mismo en el marco de un convenio o por actividades adelantadas por entes de vigilancia, control y/o certificación.</p>

        <h3>V. PRINCIPIOS</h3>
        <p>En el desarrollo, interpretación y aplicación de la presente política, se aplicarán de manera armónica e integral, los principios previstos en la Ley 1581 de 2012 sin perjuicio de otros propios de la administración de datos:</p>
        <p><span className={styles.blackColor}>PRINCIPIO DE LEGALIDAD</span>: El Tratamiento de Datos Personales realizado por <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> se sujetará a lo establecido en la Constitución, la Ley y las demás disposiciones que la desarrollen.</p>
        <p><span className={styles.blackColor}>PRINCIPIO DE FINALIDAD</span>: El tratamiento de los datos recolectados por <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> obedece al propósito específico y explícito autorizado por el titular y permitido por la ley, para lo cual se informará al titular del dato de manera clara, suficiente y previa acerca de la finalidad de la información suministrada. En caso de ser necesario modificar el uso de los datos personales, se deberá obtener nuevamente el consentimiento previo del titular.</p>
        <p><span className={styles.blackColor}>PRINCIPIO DE LIBERTAD</span>: Para ejercer el tratamiento de los datos personales de los titulares de I- COMM SOLUTIONS SAS, previamente se requiere el consentimiento expreso e informado de su Titular, incluso para los datos previstos en el artículo 10 de la Ley 1581 de 2012, sin perjuicio de la prevalencia de los principios de protección de datos personales. Los datos personales no podrán ser obtenidos o divulgados sin el previo consentimiento del titular, o en ausencia de mandato legal o judicial que releve el consentimiento.</p>
        <p><span className={styles.blackColor}>PRINCIPIO DE VERACIDAD O CALIDAD</span>: La información sujeta a Tratamiento debe ser veraz, completa, exacta, actualizada, comprobable y comprensible. Se prohíbe el tratamiento de datos parciales, incompletos, fraccionados o que induzcan a error. <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> adoptará las medidas razonables para asegurar que los datos sean precisos y suficientes y, cuando así lo solicite el Titular o cuando <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> lo determine y sea procedente, serán actualizados, rectificados o suprimidos.</p>
        <p><span className={styles.blackColor}>PRINCIPIO DE TRANSPARENCIA</span>: <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> garantiza el derecho del titular de obtener, en cualquier momento y sin restricción, información acerca de la existencia de sus datos objeto de tratamiento.</p>
        <p><span className={styles.blackColor}>PRINCIPIO DE ACCESO Y CIRCULACIÓN RESTRINGIDA</span>: El tratamiento se sujeta a los límites que se derivan de la naturaleza de los datos personales, de las disposiciones de la Ley y la Constitución. En este sentido, el tratamiento sólo podrá hacerse por personas autorizadas por el titular y/o por las personas previstas en la ley.</p>
        <p>Los datos personales, salvo la información pública, no podrán estar disponibles en internet u otros medios de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un conocimiento restringido sólo a los titulares o terceros autorizados conforme a la ley.</p>
        <p><span className={styles.blackColor}>PRINCIPIO DE SEGURIDAD</span>: Los datos personales e información usada, capturada, recolectada y sujeta a tratamiento por <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> serán objeto de protección a través de la adopción de medidas técnicas, humanas y administrativas necesarias para otorgar seguridad a los registros evitando su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</p>
        <p><span className={styles.blackColor}>PRINCIPIO DE CONFIDENCIALIDAD</span>: El personal de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> que intervenga en el Tratamiento de Datos Personales que no tengan la naturaleza de públicos están obligados a garantizar la reserva de la información, inclusive después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las actividades autorizadas por la ley y en los términos de la misma.</p>
        <p><span className={styles.blackColor}>PRINCIPIO DE INTERPRETACIÓN INTEGRAL DE DERECHOS CONSTITUCIONALES</span>: Se deberá amparar adecuadamente los Derechos constitucionales de los titulares, como son el hábeas data, el derecho al buen nombre, el derecho a la honra, el derecho a la intimidad y el derecho a la información.</p>
        <p>Los Derechos de los titulares se interpretarán en armonía y en un plano de equilibrio con el derecho a la información previsto en el artículo 20 de la Constitución y con los demás Derechos constitucionales aplicables.</p>

        <h3>VI. DERECHOS QUE LE ASISTEN AL TITULAR DE LA INFORMACIÓN</h3>
        <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> garantiza al titular de los datos personales los siguientes Derechos:</p>
        <ol type="a">
          <li>Conocer, incluir, actualizar, rectificar o corregir, excluir o solicitar la supresión de sus datos personales frente a <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> , en su condición de responsable y/o encargado del Tratamiento. Este derecho se podrá ejercer, entre otros, frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado en los términos de la Ley 1581 de 2012.</li>
          <li>Solicitar la prueba de autorización otorgada a <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, salvo cuando expresamente se exceptúe como requisito para el tratamiento (casos en los cuales no es necesaria la autorización).</li>
          <li>Ser informado en cualquier momento por <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> o el Encargado del Tratamiento, previa solicitud, respecto del uso que se ha dado a sus datos personales.</li>
          <li>Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la Ley 1581 de 2012 y las demás normas que la modifiquen, adicionen o complementen. El titular debe tener en cuenta que previamente deberá agotar el trámite de reclamo o consulta ante <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>.</li>
          <li>Revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento no se respeten los principios, derechos y garantías constitucionales y legales o en virtud de su solicitud libre y voluntaria. La solicitud de supresión de la información y la revocatoria de la autorización no procederán cuando el titular tenga un deber legal o contractual de permanecer en las bases de datos de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>.</li>
          <li>Acceder en forma gratuita a sus datos personales objeto de tratamiento a través de los canales dispuestos por <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> para tal fin.</li>
        </ol>
        <p>Los Derechos de los Titulares antes relacionados, podrán ejercerse por las siguientes personas:</p>
        <ol type="a">
          <li>Por el Titular, quien deberá acreditar su identidad en forma suficiente por los distintos medios que le ponga a disposición <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>.</li>
          <li>Por sus causahabientes, quienes deberán acreditar tal calidad.</li>
          <li>Por el representante y/o apoderado del Titular, previa acreditación de la representación o apoderamiento.</li>
          <li>Por estipulación a favor de otro o para otro.</li>
        </ol>

        <h3>VII. DEBERES COMO RESPONSABLE Y ENCARGADO DEL TRATAMIENTO</h3>
        <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> en calidad de Responsable y/o Encargado del Tratamiento de Datos Personales, sin perjuicio de las disposiciones legales, le corresponde cumplir los siguientes deberes:</p>
        <ol type="a">
          <li>Garantizar al titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de habeas data.</li>
          <li>Solicitar y conservar copia de la respectiva autorización otorgada por el titular en el formato en la que fue registrada.</li>
          <li>Informar debidamente al titular sobre la finalidad de la recolección y los Derechos que le asisten en virtud de la autorización otorgada.</li>
          <li>Respetar y conservar la información, bajo las condiciones de seguridad y privacidad de la información del titular, necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no autorizado o fraudulento.</li>
          <li>Garantizar que la información que suministre al Encargado del Tratamiento sea veraz, completa, exacta, actualizada, comprobable, comprensible y que se trate de datos cuyo tratamiento estén previamente autorizados de conformidad con la Ley y la presente política.</li>
          <li>Realizar oportunamente la actualización, rectificación o supresión de la información, atendiendo de esta forma todas las novedades respecto de los datos del titular e implementar todas las medidas necesarias para que la información se mantenga actualizada.</li>
          <li>Tramitar las consultas, reclamos y solicitudes formuladas por los Titulares, en los términos señalados por la Ley.</li>
          <li>Informar al Encargado del Tratamiento cuando determinada información se encuentre en discusión por parte del Titular, una vez se haya presentado reclamación y no haya finalizado el trámite respectivo.</li>
          <li>Informar a solicitud del titular sobre el uso dado a sus datos.</li>
          <li>Informar a la autoridad de protección de datos cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los titulares.</li>
          <li>Cumplir con las instrucciones y requerimientos que imparta la SIC.</li>
          <li>En calidad de Encargada del Tratamiento, actualizar la información reportada por los responsables del tratamiento, dentro de los cinco (5) días hábiles contados a partir de su recibo.</li>
          <li>Registrar en la base de datos la leyenda "Reclamo en Trámite" en la forma legalmente establecida.</li>
          <li>Insertar en la base de datos la leyenda "Información en discusión judicial", una vez sea notificado por parte de la autoridad competente un proceso judicial relacionado con la calidad del dato personal.</li>
          <li>Abstenerse de circular información que esté siendo controvertida por el Titular y cuyo bloqueo haya sido ordenado por la Superintendencia de Industria y Comercio.</li>
          <li>Permitir el acceso a la información únicamente a las personas que pueden tener acceso a ella, según lo previsto en el literal j) artículo 18 de la Ley 1581 de 2012.</li>
          <li>Cumplir los requerimientos e instrucciones que imparta la Superintendencia de Industria y Comercio sobre el tema en particular e informar cuando se presenten violaciones a los códigos de seguridad y existan riesgos en la administración de la información de los titulares.</li>
          <li>Usar únicamente datos cuyo tratamiento esté previamente autorizado de conformidad con lo previsto en la Ley 1581 de 2012.</li>
        </ol>

        <h3>VIII. AUTORIZACIÓN DEL TITULAR</h3>
        <p>Los datos personales que han sido suministrados y autorizados por los titulares de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, ya sea por cualquier medio escrito, verbal, físico o electrónico, o a través de acciones inequívocas al ingresar de manera voluntaria a las instalaciones, o cuando navegan en los canales tecnológicos (WEB, apps, Redes Sociales, etc.), de acuerdo con los avisos de privacidad y seguridad difundidos en los diferentes canales, aceptan el tratamiento de los mismos y autorizan su uso a <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, conforme a las finalidades descritas en esta Política y a lo establecido en la Ley 1581 de 2012.</p>
        <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> utilizará los mecanismos adecuados, e implementará y adoptará las acciones tendientes y necesarias para mantener registro o mecanismos técnicos o tecnológicos idóneos de cuándo y cómo obtuvo autorización por parte de los titulares de datos personales para el tratamiento de los mismos.</p>
        <p>Para dar cumplimiento a lo anterior, se podrán establecer archivos físicos o repositorios electrónicos realizados de manera directa o a través de terceros contratados para tal fin.</p>
        <p>La autorización del titular de la información no será necesaria en los siguientes casos:</p>
        <ol type="a">
          <li>Información requerida por una entidad pública o administrativa en ejercicio de sus funciones legales o por orden judicial.</li>
          <li>Datos de naturaleza pública.</li>
          <li>Casos de urgencia médica o sanitaria.</li>
          <li>Tratamiento de información autorizado por la ley para fines históricos, estadísticos o científicos.</li>
          <li>Datos relacionados con el Registro Civil de las personas.</li>
        </ol>
        <p>No obstante, aún en los casos de los literales mencionados aplica la autorización sin perjuicio de la prevalencia de los principios de protección de datos personales, según lo dispuesto por la Corte Constitucional mediante Sentencia C-748 del 06 de octubre de 2011.</p>

        <h3>IX. AVISO DE PRIVACIDAD</h3>
        <p>El AVISO DE PRIVACIDAD es la comunicación verbal, escrita o electrónica que es puesta a disposición del Titular para darle a conocer la información, existencia y las formas de acceder a la política de Tratamiento de Datos Personales que le serán aplicables a cualquier titular de la Información.</p>
        <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, al momento de recolectar o capturar los datos personales, implementará el aviso de privacidad, de conformidad con lo previsto en los artículos 2.2.2.25.3.2. y 2.2.2.25.3.3. del Decreto 1074 de 2015 -Decreto Único Reglamentario del Sector Comercio, Industria y Turismo.</p>
        <p>El Aviso de Privacidad, como mínimo, deberá contener la siguiente información:</p>
        <ol type="a">
          <li>Nombre o razón social y datos de contacto del responsable del tratamiento.</li>
          <li>La finalidad de la recolección de los datos y el tipo de tratamiento al que serán sometidos.</li>
          <li>Los Derechos que tiene el titular de la información.</li>
          <li>Los mecanismos y canales de atención dispuestos por el responsable de los datos para que el titular conozca la política y acceda a sus Derechos Constitucionales.</li>
          <li>En caso de que la organización o el responsable recolecte datos personales sensibles tales como el origen racial o étnico, orientación sexual, filiación política o religiosa, etc.; debe explicarle al titular de los datos el carácter sensible que posee este tipo de información y, además, debe darle la opción de elegir si responde o brinda estos datos.</li>
        </ol>
        <p>Para la difusión del aviso de privacidad y de la Política de Tratamiento de Datos Personales, el responsable podrá valerse de documentos, formatos electrónicos, medios verbales o cualquier otra tecnología, siempre y cuando garantice y cumpla con el deber de informar al titular.</p>

        <h3>X. TRATAMIENTO Y FINALIDAD AL CUAL SERÁN SOMETIDOS LOS DATOS</h3>
        <p>El tratamiento para los datos de carácter personal que realice <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> corresponderá y se realizará bajo el marco legal y normativo vigente, conforme a su condición de empresa por acciones simplificadas legalmente constituida y serán todos los datos necesarios para el cumplimiento de su objeto social.</p>
        <p>Además de lo anterior, y en todo caso, los datos personales podrán ser recolectados y tratados para los siguientes fines:</p>
        <ol type="a">
          <li className={styles.blackColor}>Gestión Administrativa y Financiera</li>
          <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> realizará Tratamiento de Datos Personales para el envío de información interna y externa en procesos y actividades como: Procesos de selección y/o oportunidades de empleos, convocatorias, procesos de contratación; afiliación al Sistema de Seguridad Social Integral (Salud, Pensión y Riesgos Laborales); implementación del Sistema de Seguridad y Salud en el Trabajo; gestión de proveedores y contratistas bajo el cumplimiento normativo; gestión de adquisición de bienes y servicios; gestión de operaciones financieras; registros contables, ejecución de convenios con terceros para el desarrollo y ejecución de proyectos; pago de nómina, bienestar organizacional; afiliación a cajas de compensación familiar; participación en eventos corporativos, información de gestión médica y de salud; difusión de información a través de los medios de comunicación de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>; temas publicitarios, comerciales, investigativos, estadísticos, de mercadeo; inteligencia de mercados y mediciones de percepción y satisfacción; marketing digital; networking y, en general, para todas las actividades que se enmarquen dentro del objeto social de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, así como para los titulares que sean objeto de tratamiento como: empleados, ex-empleados, pensionados, proveedores o contratistas y demás que tuvieren establecida o establezcan una relación permanente u ocasional con <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>.</p>
          <li className={styles.blackColor}>Gestión de Empleados, Proveedores y Contratistas</li>
          <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> realizará Tratamiento de Datos Personales de empleados, ex-empleados, proveedores, y/o contratistas y demás que puedan llegar a tener cualquier vinculo temporal o permanente con <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, y que en virtud del cumplimiento de sus funciones o actividades sea necesario solicitar información de carácter personal, traten información personal de los titulares o actúen como encargados del tratamiento. Lo anterior bajo la finalidad de acciones como pagos de honorarios, liquidaciones contractuales, reportes de pagos, reportes que por Ley o por políticas internas tiene la obligación de realizar, solicitud de informes, información de procesos de selección y/o oportunidades de empleos, convocatorias, procesos de contratación, afiliación al Sistema de Seguridad Social Integral, implementación del Sistema de Seguridad y Salud en el Trabajo, gestión de proveedores y contratistas, ejecución de convenios con terceros para el otorgamiento de beneficios o desarrollo de proyectos, pago de nómina, bienestar organizacional, afiliación a cajas de compensación familiar, atención de consultas, peticiones, solicitudes, acciones y reclamos, hechas por el Titular de la información o por sus legitimarios.</p>
          <li className={styles.blackColor}>Gestión de Tratamiento de Datos Sensibles</li>
          <p>El Tratamiento de los Datos Sensibles está prohibido, excepto cuando:</p>
          <ol>
            <li>El Titular haya dado su autorización explícita a dicho Tratamiento, salvo en los casos que por ley no sea requerido el otorgamiento de dicha autorización;</li>
            <li>El Tratamiento sea necesario para salvaguardar el interés vital del Titular y este se encuentre física o jurídicamente incapacitado. En estos eventos, los representantes legales deberán otorgar su autorización;</li>
            <li>El Tratamiento sea efectuado en el curso de las actividades legítimas y con las debidas garantías por parte de una fundación, ONG, asociación o cualquier otro organismo sin ánimo de lucro, cuya finalidad sea política, filosófica, religiosa o sindical, siempre que se refieran exclusivamente a sus miembros o a las personas que mantengan contactos regulares por razón de su finalidad. En estos eventos, los datos no se podrán suministrar a terceros sin la autorización del Titular;</li>
            <li>El Tratamiento se refiera a datos que sean necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial;</li>
            <li>El Tratamiento tenga una finalidad histórica, estadística o científica. En este evento deberán adoptarse las medidas conducentes a la supresión de identidad de los Titulares.</li>
          </ol>
          <p>En los anteriores eventos es posible el Tratamiento de los Datos Personales, siempre y cuando se cumplan las siguientes obligaciones:</p>
          <ol>
            <li>Informar al titular que por tratarse de datos sensibles no está obligado a autorizar su Tratamiento.</li>
            <li>Informar al titular de forma explícita y previa, además de los requisitos generales de la autorización para la recolección de cualquier tipo de dato personal, cuáles de los datos que sean objeto de Tratamiento son sensibles y la finalidad del Tratamiento, así como obtener su consentimiento expreso.</li>
          </ol>
          <li className={styles.blackColor}>Gestión de Tratamiento de Datos de Menores de Edad</li>
          <p>El Tratamiento de datos personales de niños, niñas y adolescentes está prohibido, excepto cuando se trate de datos de naturaleza pública, de conformidad con lo establecido en el artículo 7 de la Ley 1581 de 2012 y cuando dicho Tratamiento cumpla con los siguientes parámetros y requisitos:</p>
          <ol>
            <li>Que la finalidad del tratamiento responda al interés superior de los niños, niñas y adolescentes.</li>
            <li>Que se asegure el respeto de sus derechos fundamentales de los niños, niñas y adolescentes.</li>
            <li>De acuerdo con la madurez, autonomía y capacidad para entender el asunto, se debe tener en cuenta la opinión del el niño, niña o adolescente.</li>
            <li>Que se cumpla con los principios previstos en la Ley 1581 de 2012 para el tratamiento de datos personales.</li>
          </ol>
          <p>Cumplidos los anteriores requisitos, el representante legal del niño, niña o adolescente otorgará la autorización respetando la opinión del menor de edad.</p>
          <li className={styles.blackColor}>Gestión de Riesgos y Emergencias, Urgencias Médicas o Sanitarias</li>
          <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> realizará una adecuada gestión de riesgos que permita atender de manera oportuna y adecuada emergencias que puedan afectar la integridad física de las personas, es necesario tener en tiempo real información de las personas que se encuentren en el desarrollo de nuestras actividades que permita su análisis. De igual manera, se requiere la recolección y uso de datos en caso de urgencia médica o sanitaria, entendiendo que todos los datos sobre la salud de las personas son información sensible y su tratamiento requiere mayores medidas de seguridad, restricción de circulación, acceso y uso.</p>
          <li className={styles.blackColor}>Gestión Plataformas WEB, Desarrollos y Aplicaciones Tecnológicas</li>
          <p>El Tratamiento de los Datos Personales suministrados a través de herramientas tecnológicas, de inteligencia artificial, electrónicas, páginas web, sistemas de información, aplicaciones web, app ́s, software, entre otros, se regirá bajo el cumplimiento de la normativa vigente aplicable según la presente Política, y su finalidad se desarrollará de acuerdo al objeto y legitimación que se indiquen en cada uno de los formularios de recolección de datos personales de las herramientas mencionadas.</p>
          <p>Cuando se ingrese a los sitios web o se utilice un servicio como desarrollo o aplicación tecnológica de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, se recopilará la información necesaria para brindar acceso, para el funcionamiento y para cumplir con los requisitos legales y de seguridad que son necesarios para el funcionamiento del servicio, o su registro en el mismo, como: número de identificación, email, nombres completos, contraseñas, las direcciones IP y los ajustes del navegador, entre otros. Durante la visita a las plataformas y/o aplicaciones tecnológicas también se recopilará información acerca de las actividades realizadas en las mismas, para personalizar la experiencia en el sitio, como, por ejemplo, registrar preferencias y ajustes, y recopilar estadísticas, que permiten a <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> mejorar y a seguir desarrollando productos y servicios para sus necesidades.</p>
          <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, actuara como Encargado de tratamiento de datos personales, y dará cumplimiento a lo establecido en la presente política en el numeral VII, para todos los fines determinados y establecidos con los Responsables de la Información.</p>
          <li className={styles.blackColor}>Gestión de Datos Biométricos - Imágenes y Video</li>
          <p>La información recolectada como datos biométricos, imágenes fotográficas o de video, capturadas en nuestros eventos, cursos, talleres, actividades lúdicas o sociales, sistemas de video-vigilancia, ingresos peatonales o vehiculares y demás actividades que se puedan ejecutar, se utilizarán o podrán ser tratadas para fines de determinar de manera cierta la identidad de las personas que ingresan a la instalaciones, seguridad de los bienes y de las personas que nos visitan, convivencia de las personas, promoción de eventos, socialización de actividades especificas internas, soporte o constancia de las labores y/o actividades desarrolladas.</p>
          <p>Las imágenes serán tratadas de manera adecuada, pertinente y no excesiva, en relación con el ámbito, y las finalidades determinadas, legítimas y explícitas, que hayan justificado su tratamiento y durante el tiempo necesario. De acuerdo con el tratamiento de datos sensibles como son los datos personales de los de menores de edad estos deberán estar previamente autorizados por su Representante Legal o Tutor, y los datos tratados se regirán única y exclusivamente para el registro y control en eventos o actividades culturales y de integración familiar, desarrollo de actividades recreativas o deportivas, y temas de seguridad humana, y no tendrán ningún otra finalidad a la estipulada en la presente política.</p>
        </ol>

        <h3>XI. PERSONAS A QUIENES SE LES PUEDE SUMINISTRAR LA INFORMACIÓN</h3>
        <p>La información que reúna las condiciones establecidas en la Ley podrá suministrarse a las siguientes personas:</p>
        <ol type="a">
          <li>A los titulares, sus causahabientes (cuando aquellos falten) o sus representantes legales.</li>
          <li>A las entidades públicas o administrativas en ejercicio de sus funciones legales o por orden judicial.</li>
          <li>A los terceros autorizados por el titular o por la Ley.</li>
        </ol>

        <h3>XII. OFICIAL DE DATOS PERSONALES Y ATENCIÓN DE CONSULTAS, RECLAMOS Y SOLICITUDES</h3>
        <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> asignará una persona que asumirá las funciones de Oficial de Tratamiento de Datos Personales, conforme a lo estipulado en la Guía de Responsabilidad Demostrada, y será el responsable de velar por el cumplimiento, los lineamientos, seguimiento y articulación de la Política de Tratamiento de Datos Personales y gestionar todos los procedimientos que estipule el ente de control, así como todas las consultas, reclamos y solicitudes de los titulares para el ejercicio de los Derechos a que se refiere la Ley 1581 de 2012, sus Decretos reglamentarios y las Circulares Externas expedidas por la Superintendencia de Industria y Comercio y demás normas o disposiciones que las modifiquen, complementen o reemplacen.</p>
        <p>En cumplimiento de la normatividad vigente de tratamiento y protección de datos personales, el titular o representante y/o apoderado del Titular puede tramitar las peticiones, consultas y reclamos de manera directa, y por escrito, aportando la documentación que soporte su requerimiento, a través de los siguientes canales de atención:</p>
        <ul>
          <li>OFICINA DE CORRESPONDENCIA: Comunicación dirigida al Oficial de Protección de Datos Personales de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span></li>
          <li>DIRECCIÓN FISICA: Carrera 47A # 101A - 58, Bogotá.</li>
          <li>CORREO ELECTRÓNICO: <a href='mailto:protecciondedatos@i-comm.co'>protecciondedatos@i-comm.co</a></li>
          <li>TELEFÓNO DEL RESPONSABLE: +57 601 6377015</li>
        </ul>

        <h3>XIII. PROCEDIMIENTO PARA EJERCICIO DE LOS DERECHOS DE LOS TITULARES</h3>
        <p>El titular de los datos o su representante y/o apoderado, puede ejercer sus Derechos a consultar, actualizar, rectificar, suprimir y revocar la autorización bajo el siguiente procedimiento.</p>
        <p><span className={styles.blackColor}>PRESENTACIÓN DE LA SOLICITUD</span>. La solicitud debe presentarse en cualquiera de nuestros canales de atención anteriormente mencionados.</p>
        <p><span className={styles.blackColor}>REQUISITOS DE LA SOLICITUD</span>. La solicitud debe contemplar los siguientes requisitos:</p>
        <ol>
          <li>Nombres y apellidos del titular.</li>
          <li>Número de identificación del titular.</li>
          <li>Datos de localización del titular.</li>
          <li>Descripción de los hechos que dan lugar a la consulta, reclamo o revocatoria.</li>
          <li>Documentos que considere soportan su consulta, reclamo o revocatoria.</li>
          <li>Medio por el cual desea recibir respuesta.</li>
          <li>Nombre del peticionario, el cual, si es diferente al titular, debe adjuntar los documentos que le permitan actuar en su nombre.</li>
          <li>Firma del peticionario.</li>
        </ol>
        <p>Se debe tener en cuenta las siguientes indicaciones:</p>
        <ol type="a">
          <li>Si el Titular actúa por sí mismo, deberá acreditar su identidad adjuntando copia del documento de identidad, manifestando de manera clara y expresa el motivo de la solicitud.</li>
          <li>Si se trata de causahabiente, deberá adjuntar copia del documento de identidad, registro civil de defunción del Titular, manifestando de manera clara y expresa, el motivo de la solicitud.</li>
          <li>En caso de que el Titular actúe mediante representación o apoderado, deberá acreditarse tal calidad mediante poder debidamente conferido por el Titular del dato recolectado, manifestando de manera clara y expresa el motivo de la solicitud.</li>
          <li>En caso de actuar por medio de otra persona, se deberá aportar poder y/o autorización debidamente conferida por el Titular, y se deberá manifestar de manera clara y expresa el motivo de la solicitud.</li>
          <li>Para el caso de los menores de edad se solicitará a su representante o acudiente la presentación del documento que acredite el parentesco o relación que lo vincule como representante legal del menor (Registro civil de nacimiento).</li>
        </ol>
        <p><span className={styles.blackColor}>GESTIÓN DE LA SOLICITUD</span>. Todas las solicitudes que realicen las personas legitimadas para ejercer cualquiera de los Derechos previamente mencionados se gestionarán de la siguiente manera:</p>
        <ol type="i">
          <li>Si la solicitud corresponde a una consulta, esta será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la solicitud. Cuando no fuere posible atender la consulta en el término indicado, se informará al interesado y/o Titular los motivos de la demora, e indicará la fecha en la cual será atendida su consulta, la cual no podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</li>
          <li>Si la solicitud corresponde a un reclamo con el objeto de corrección, actualización o supresión, esta será atendida en un término máximo de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender la solicitud dentro de dicho término, se informará al interesado y/o Titular los motivos de la demora y la fecha en que se atenderá la solicitud, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</li>
          <p>En todo caso, si la solicitud resulta incompleta o no es clara, se requerirá al interesado y/o Titular dentro de los cinco (5) días hábiles siguientes a la recepción de la comunicación para que subsane o aclare las falencias; una vez aclarada la solicitud, se incluirá en la base de datos la leyenda "reclamo en trámite".</p>
          <li>Si transcurridos dos (2) meses desde la fecha del requerimiento, el solicitante no presenta la información requerida, se entenderá que ha desistido de la solicitud.</li>
        </ol>
        <p>Adicionalmente, en el caso de solicitud de supresión o eliminación de las bases de datos, el Titular y/o interesado deberá manifestar de forma clara y concreta el motivo por el cual realiza tal solicitud, dicho motivo podrá ser la simple voluntad del titular; y una vez recibida la solicitud y verificada la información, se contará con el término de diez (10) días hábiles a la verificación para proceder a la eliminación y/o supresión de los datos personales del Titular o solicitante de la(s) base(s) de datos de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>.</p>
        <p>Finalizada la eliminación, se le informará al Titular o solicitante por el mismo canal mediante el cual se recibió la solicitud, y enviará prueba que respalde la comunicación. De igual forma, se informará al Titular o solicitante, cuando no fuere posible la eliminación total de los datos personales de las bases de datos de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>, por ejemplo, por temas administrativos, contables, fiscales, legales, contractuales e históricos de la información, como la obligación en temas tributarios. No obstante, inhabilitará la información solicitada.</p>
        <p>Sin perjuicio de lo anterior, y en caso de que sea requerida esta información por alguna entidad pública en ejercicio de sus funciones o por notificación judicial se reactivará el dato para cumplir con los términos de ley. Si <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> no es la entidad competente para resolver un reclamo, dará traslado de este a quien corresponda en un término máximo de dos (2) días hábiles, si el nuevo responsable es identificable se informará de la situación al interesado para que pueda hacer seguimiento o identifique claramente la entidad a la cual debe dirigirse.</p>

        <h3>XIV. MEDIDAS DE SEGURIDAD PARA LA PROTECCIÓN Y PRIVACIDAD DE LA INFORMACIÓN</h3>
        <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> se compromete a adoptar las medidas de seguridad impartidas por las Superintendencia de Industria y Comercio acorde Artículo 2.2.2.25.3.7. del Decreto 1074 de 2015 - Decreto Único Reglamentario del Sector Comercio, Industria y Turismo, para el Tratamiento de Datos Personales. No obstante, lo anterior, <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> en su proceso de mejora continua establece controles físicos y tecnológicos que permitan, en la medida de lo posible, conservar la información y su contenido para limitar el acceso de terceros no autorizados.</p>

        <h3>XV. SANCIONES APLICABLES</h3>
        <p>El incumplimiento de las disposiciones aquí contempladas, dará lugar a las sanciones previstas por la Superintendencia de Industria y Comercio podrá imponer a los responsables del tratamiento y encargados del tratamiento las siguientes sanciones:</p>
        <ul>
          <li>Multas de crácter personal e institiucional hasta por el equivalente de dos mil (2.000) salarios mínimos mensuales legales vigentes al momento de la imposición de la sanción. Las multas podrán ser sucesivas mientras subsista el incumplimiento que las originó.</li>
          <li>Suspensión de las actividades relacionadas con el tratamiento hasta por un término de seis (6) meses. En el acto de suspensión se indicarán los correctivos que se deberán adoptar.</li>
          <li>Cierre temporal de las operaciones relacionadas con el Tratamiento una vez transcurrido el término de suspensión sin que se hubieren adoptado los correctivos ordenados por la Superintendencia de Industria y Comercio.</li>
          <li>Cierre inmediato y definitivo de la operación que involucre el Tratamiento de datos sensibles.</li>
        </ul>

        <h3>XVI. TRANSFERENCIA Y TRANSMISIÓN INTERNACIONAL DE DATOS PERSONALES</h3>
        <p>Para las transferencias internacionales de datos personales de los titulares, se tomarán las medidas necesarias para garantizar el tratamiento conforme al cumplimiento de las finalidades establecidas y su uso será solamente mientras esta dure, de forma que no podrá ser usada o destinada para propósitos o fines diferentes.</p>
        <p>Para la transferencia internacional de datos personales se observará lo previsto en el artículo 26 de la Ley 1581 de 2012. Las transmisiones internacionales de datos personales que efectúe <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> no requerirán ser informadas al Titular ni contar con su consentimiento cuando medie un contrato de transmisión de datos personales de conformidad con el artículo 2.2.2.25.5.2 del Decreto 1074 de 2015 -Decreto Único Reglamentario del Sector Comercio, Industria y Turismo.</p>

        <h3>XVII. ACEPTACIÓN DE LA POLITICA DE TRATAMIENTO DE DATOS PERSONALES</h3>
        <p>El titular de la Información acepta el tratamiento de sus datos personales conforme a las finalidades indicadas en la presente política de tratamiento de datos personales de <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span>.</p>

        <h3>XVIII. CAMBIOS EN LA POLITICA DE TRATAMIENTO DE DATOS PERSONALES</h3>
        <p><span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> se reserva el derecho de modificar la presente Política según lo requiera para su funcionamiento y cumplimiento normativo. No obstante, lo anterior en caso de haber cambios sustanciales en el contenido de las políticas de privacidad y tratamiento de datos, así como cambios normativos, se dará a conocer su última versión y se realizará la divulgación de dicha actualización o modificación por medio de canales físicos, electrónicos o página Web y demás que disponga <span className={styles.icommColor}>I-COMM SOLUTIONS SAS</span> a todos sus titulares.</p>

        <p className={styles.textCenter}>
          <a href={downloadFile}>DESCARGAR PDF</a>
        </p>

        <hr style={{ mt: 30 }} />
        <Row
          sx={{ margin: 0 }}
        >
          <Col md={6} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <p>Atentamente,<br />
              <strong>iComm Solutions S.A.S.</strong>
            </p>
          </Col>
          <Col md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <small>Fecha Actualización:<br />
              1 de Septiembre de 2020
            </small>
          </Col>
        </Row>
      </Box>
    </Box>
  </React.Fragment>
)

export default PrivacyPolicyPage
